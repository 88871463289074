"use client";

import Image from "next/image";
import styles from "./layout.module.css"


export default function MainLayout({
  children,
  sideContent,
  leftContainerStyle = "flex-1",
  rightContainerStyle = "flex-1",
}: {
  children: React.ReactNode,
  sideContent?: React.ReactNode
  leftContainerStyle?: string,
  rightContainerStyle?: string,
}) {
 return <div className="flex flex-row">
    <div id="layers-container" className={`relative ${styles.landingPageBg} ${leftContainerStyle}`}>
      {/*<div id="background-layer" className={`absolute inset-0 z-10 ${styles.landingPageBg}`} />*/}

      {/* Images layer */}
      <div
        id="images-layer"
        className="absolute content-start flex flex-col h-full   inset-0 items-center overflow-hidden select-none z-10"
        style={{width: "calc(100vw - (100vw - 100%))"}}
      >
        <div className="sm:h-6 h-20"/>
        <div style={{minWidth: "1800px", minHeight: "1143px"}}>
          <Image
            src="/landing_page/shapes.webp"
            alt="A playful depiction of gray, black and purple shapes"
            width="1800"
            height="1143"
            draggable="false"
          />
        </div>
      </div>

      {/* Content layer */}
      <div id="content-layer" className="absolute inset-0 flex flex-col items-center overflow-hidden z-20">
        <div className="flex flex-col w-full">
          {children}
        </div>
      </div>
    </div>
    <script dangerouslySetInnerHTML={{ 
    __html: `
      let debounceTimer;
  
      function adjustHeight() {
        const contentLayer = document.getElementById('content-layer');
        if (contentLayer === undefined || contentLayer === null) {
          return;
        }
        const contentHeight = [...contentLayer.children].reduce((total, child) => {
          return total + child.offsetHeight;
        }, 0);
        
        contentLayer.style.height = contentHeight + 'px';

        // Layers container
        const layersContainer = document.getElementById('layers-container');
        layersContainer.style.minHeight = '100vh';
        layersContainer.style.height = contentHeight + 'px';

        // Image layer
        const imagesLayer = document.getElementById('images-layer');
        imagesLayer.style.minHeight = '100vh';
        imagesLayer.style.height = contentHeight + 'px';
      }
  
      function debouncedAdjustHeight() {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(adjustHeight, 100); // 100ms debounce delay
      }
  
      document.addEventListener('DOMContentLoaded', function() {
        const resizeObserver = new ResizeObserver(debouncedAdjustHeight);
        const mutationObserver = new MutationObserver(debouncedAdjustHeight);
  
        resizeObserver.observe(document.body);
        mutationObserver.observe(document.body, { childList: true, subtree: true });
        window.addEventListener('resize', debouncedAdjustHeight);
        window.addEventListener('scroll', debouncedAdjustHeight);
  
        // Initial adjustment
        adjustHeight();
      });
    `
    }} />
    <div className={sideContent ? `${rightContainerStyle}` : ""}>
      {sideContent}
    </div>
  </div>
}